export const npmAuditRunHistoryHeaders = [
  "runId",
  "vulnerabilities",
  "finishTime",
  "options",
];

export const testEvolveAccessibilityRunHistoryHeaders = [
  "runId",
  "approved",
  "checksPerformed",
  "violations",
  "severity",
  "runProperties",
  "finishTime",
  "timeUpdated",
  "options",
];

export const testEvolveVisualRunHistoryHeaders = [
  "runId",
  "approved",
  "checksPerformed",
  "totalFailedChecks",
  "testType",
  "runProperties",
  "finishTime",
  "timeUpdated",
  "options",
];

export const testEvolveLighthouseRunHistoryHeaders = [
  "runId",
  "approved",
  "lowestPerformanceScore",
  "lowestAccessibilityScore",
  "lowestBestPracticesScore",
  "lowestSEOScore",
  "runProperties",
  "finishTime",
  "timeUpdated",
  "options",
];

export const runPropertiesRank = [
  "source",
  "environment",
  "app_name",
  "device",
  "os",
  "os_version",
  "browser",
  "browser_version",
  "viewport_size",
  "path",
  "tags",
  "spark_version",
  "node_version",
  "ruby_version",
  "python_version",
];

export const settingsTabNames = [
  { name: "users", requiresAdmin: true },
  { name: "reports", requiresAdmin: true },
  { name: "api-keys", requiresRole: "API_ADMIN" },
];

export const KEYS = {
  ENTER: "Enter",
  ESCAPE: "Escape",
};

export const COPIED_TIMEOUT = 2000;
export const POLLING_INTERVAL = 10000;
export const RELEASE_VIEW_POLLING_INTERVAL = 10000;

export const EMAIL_REPORT_LIMIT = 5;

export const TEST_EVOLVE_DOCUMENTATION = "https://testevolve.github.io/";
export const TEST_EVOLVE_DOCUMENTATION_RUN_APPROVAL =
  "https://testevolve.github.io/docs/Halo/approval-statuses";
export const TEST_EVOLVE_HOME_URL = "https://www.testevolve.com/halo-home";
export const APP_DOWNLOAD_DMG = version =>
  `https://storage.googleapis.com/test-evolve-app-release/macos/test-evolve-flare-${version}-mac.dmg`;
export const APP_DOWNLOAD_MSI = version =>
  `https://storage.googleapis.com/test-evolve-app-release/windows/test-evolve-flare-${version}-win.exe`;
export const APP_DOWNLOAD_APP_IMAGE = version =>
  `https://storage.googleapis.com/test-evolve-app-release/linux/test-evolve-flare-${version}-linux.AppImage`;
export const APP_DOWNLOAD_RPM = version =>
  `https://storage.googleapis.com/test-evolve-app-release/linux/test-evolve-flare-${version}-linux.rpm`;
export const APP_DOCUMENTATION_LINK =
  "https://testevolve.github.io/docs/Flare/setup";
export const METADATA_DOCUMENTATION_LINK =
  "https://testevolve.github.io/docs/Spark/CI/metadata";
export const GET_VERSION_URL = {
  path: "https://test-evolve-app-release.storage.googleapis.com/latest.txt",
};
export const TERMS_URL =
  "https://www.testevolve.com/test-evolve-licence-terms-and-conditions";
export const PRIVACY_POLICY_URL =
  "https://www.testevolve.com/website-terms-and-conditions";

export const SUPPORT_EMAIL = "support@testevolve.com";

export const EXPORT_FILE_NAME = ":label_run_summaries.csv";
export const EXPORT_REPORT_FILE_NAME = ":label_run_failures.csv";

export const TEST_CATEGORIES = {
  NPM_AUDIT: "npm.audit",
  TEST_EVOLVE_VISUAL: "testevolve.visual",
  TEST_EVOLVE_AUDIT_AXE: "testevolve.audit_axe",
  TEST_EVOLVE_FUNCTIONAL: "testevolve.functional",
  TEST_EVOLVE_AUDIT_LIGHTHOUSE: "testevolve.audit_lighthouse",
  TEST_EVOLVE_EXPLORATORY: "testevolve.exploratory",
  TEST_EVOLVE_ACCESSIBILITY: "testevolve.accessibility",
};

export const PASS_PERCENTAGE = "passPercentage";
export const DURATION = "duration";
export const VIOLATIONS = "violations";
export const SCORES = "scores";

export const STATUSES = {
  NEW: "new",
  FAILED: "failed",
  PASSED: "passed",
  SKIPPED: "skipped",
  ERROR: "error",
};

export const roles = {
  USER_ADMIN: "USER_ADMIN",
  PROJECT_USER: "PROJECT_USER",
  PARTNER_ADMIN: "PARTNER_ADMIN",
  PARTNER_USER: "PARTNER_USER",
  CUSTOMER_ADMIN: "CUSTOMER_ADMIN",
  API_ADMIN: "API_ADMIN",
};

export const permissions = {
  REMOVE_PROJECT: "REMOVE_PROJECT",
  CREATE_PROJECT: "CREATE_PROJECT",
  PROJECT_USER: "PROJECT_USER",
  DELETE_NODE: "DELETE_NODE",
};

// BILLING
export const FREE = "FREE";
export const EVALUATION = "EVALUATION";
export const BUSINESS = "BUSINESS";
export const TEAM = "TEAM";
export const PARTNER = "partner";
export const UNLIMITED = "Unlimited";
export const MONTH = "MONTH";
export const YEAR = "year";
export const ANNUAL = "Annual";
export const ANNUAL_ALL_CAPS = "ANNUAL";
export const ANNUALLY = "Annually";
export const MONTHLY = "Monthly";
export const MONTHLY_ALL_CAPS = "MONTHLY";
export const FLAT_AMOUNT = "flatAmount";
export const ALL_SEATS_PRICE = "allSeatsPrice";
export const THRESHOLD = "threshold";
export const PERIOD_CHANGE = {
  MONTH_YEAR: "an Annual",
  YEAR_MONTH: "a monthly",
};
export const PAGE = "page";
export const NO_UPGRADE_SUBS = [BUSINESS, PARTNER];

export const GB_COUNTRY_CODE = "GB";
export const CURRENCY_ISO_CODES = {
  GBP: "GBP",
  USD: "USD",
};

export const PLAN_INTERVALS = {
  MONTH: "month",
  ANNUAL: "annual",
};

// ADDRESS
export const ADDRESS_HEADERS = {
  name: { title: "Name", address: false },
  email: { title: "*Email", address: false },
  line1: { title: "Address Line 1", address: true },
  line2: { title: "Address Line 2", address: true },
  city: { title: "Town/City", address: true },
  state: { title: "State/County", address: true },
  postal_code: { title: "*Postal/Zip Code", address: true },
  country: { title: "Country", address: true },
  phone: { title: "Phone Number", address: false },
  company_name: { title: "Company", address: false },
  vat_number: { title: "VAT Number", address: false },
};

export const ADDRESS_OPTIONS = [
  "line1",
  "line2",
  "city",
  "postal_code",
  "state",
  "country",
];

// BILLING HISTORY HEADINGS
export const BILLING_HISTORY_HEADERS_DESKTOP = [
  "billing.invoice.header.desktop.invoiceNumber",
  "billing.invoice.header.desktop.date",
  "billing.invoice.header.desktop.period",
  "billing.invoice.header.desktop.basis",
  "billing.invoice.header.desktop.amount",
  "billing.invoice.header.desktop.download",
];

export const BILLING_HISTORY_HEADERS_DESKTOP_PARTNER = [
  "billing.invoice.header.desktop.invoiceNumber",
  "billing.invoice.header.desktop.date",
  "billing.invoice.header.desktop.period",
  "billing.invoice.header.desktop.basis",
  "billing.invoice.header.desktop.type",
  "billing.invoice.header.desktop.amount",
  "billing.invoice.header.desktop.download",
];

export const BILLING_HISTORY_HEADERS_MOBILE = [
  "billing.invoice.header.mobile.invoiceNumber",
  "billing.invoice.header.mobile.date",
  "billing.invoice.header.mobile.amount",
  "billing.invoice.header.mobile.download",
];

export const DATE_OPTIONS = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

export const LATEST = "latest";

export const RUN_PROPERTIES = {
  "testevolve.": [],
  "testevolve.unknown": [],
  "testevolve.undefined": [],
  "testevolve.api": ["os"],
  "testevolve.desktop_app": ["os"],
  "testevolve.mobile_app": ["source", "os"],
  "testevolve.browser": ["source", "device", "os", "browser"],
};

export const PROJECT = "project";
export const REDIRECT = "redirect";

export const CSV_HEADERS = { "Content-Type": "text/csv", Accept: "text/csv" };

export const RUN_STATUSES = {
  IN_PROGRESS: "in_progress",
  COMPLETE: "complete",
  ABANDONED: "abandoned",
  OPEN: "open",
  CLOSED: "closed",
};

export const RUN_CATEGORIES = {
  functional: "testevolve.functional",
  visual: "testevolve.visual",
  audit: "testevolve.audit_lighthouse",
  accessibility: "testevolve.audit_axe",
};

export const PNG_HEADERS = { "Content-Type": "image/png", Accept: "image/png" };

export const APPROVED_STATUS = "APPROVED";
export const AUTO_APPROVED_STATUS = "AUTO_APPROVED";
export const REVIEWED_STATUS = "REVIEWED";

export const APPROVAL_NOTES_LENGTH = 128;
export const RELEASE_NAME_MIN_LENGTH = 1;
export const RELEASE_NAME_MAX_LENGTH = 50;
export const RELEASE_DESC_MAX_LENGTH = 255;

export const RELEASE = "release";
export const NODE = "node";

export const DATE_FORMAT_YYYMMDD = "yyyy-MM-dd";

const NAME = "name";
const DESCRIPTION = "description";
const OPEN_DATE = "open_date";
const CLOSE_DATE = "close_date";

export const RELEASE_OBJ = {
  NAME,
  DESCRIPTION,
  OPEN_DATE,
  CLOSE_DATE,
};

export const RELEASE_STATUSES = {
  OPEN: "OPEN",
  CLOSED: "CLOSED",
};

export const MENU_STATE = {
  OPEN: "open",
  CLOSED: "closed",
};

export const TABS = {
  SCENARIOS: "scenarios",
  BUGS: "bugs",
  METADATA: "metadata",
  INSIGHTS: "insights",
  FEATURES: "features",
  SUMMARY: "summary",
};

export const LOADING = "loading";

export const ESCAPE = "Escape";
export const CLICK = "click";
export const KEYDOWN = "keydown";

export const PLATFORM_MAC = "MAC";
export const PLATFORM_WINDOWS = "WINDOWS";
export const PLATFORM_LINUX = "LINUX";

export const PLATFORM = {
  [PLATFORM_MAC]: [
    "Mac OS",
    "macOS",
    "Macintosh",
    "MacIntel",
    "MacPPC",
    "Mac68K",
  ],
  [PLATFORM_WINDOWS]: ["Win32", "Win64", "Windows", "WinCE"],
  [PLATFORM_LINUX]: ["Linux"],
};

export const PLATFORM_DOWNLOAD_LINK = {
  [PLATFORM_MAC]: APP_DOWNLOAD_DMG,
  [PLATFORM_WINDOWS]: APP_DOWNLOAD_MSI,
  [PLATFORM_LINUX]: APP_DOWNLOAD_APP_IMAGE,
};

export const DOCUMENTATION_LINK_CREATE_PROJECT =
  "https://testevolve.github.io/docs/quick-start-updated#3a--create-a-new-project";
export const DOCUMENTATION_LINK_RUNNING_TESTS =
  "https://testevolve.github.io/docs/quick-start-updated#3b--running-an-example-test-and-publishing-results-to-halo";

export const PROVIDERS = {
  SPARK: "spark",
  MANUAL: "manual",
};

export const CATEGORY_PROVIDER_MAP = {
  [TEST_CATEGORIES.NPM_AUDIT]: PROVIDERS.SPARK,
  [TEST_CATEGORIES.TEST_EVOLVE_VISUAL]: PROVIDERS.SPARK,
  [TEST_CATEGORIES.TEST_EVOLVE_AUDIT_AXE]: PROVIDERS.SPARK,
  [TEST_CATEGORIES.TEST_EVOLVE_FUNCTIONAL]: PROVIDERS.SPARK,
  [TEST_CATEGORIES.TEST_EVOLVE_AUDIT_LIGHTHOUSE]: PROVIDERS.SPARK,
  [TEST_CATEGORIES.TEST_EVOLVE_EXPLORATORY]: PROVIDERS.MANUAL,
  [TEST_CATEGORIES.TEST_EVOLVE_ACCESSIBILITY]: PROVIDERS.MANUAL,
};

export const ENTRY_TYPES = {
  BUG: "BUG",
  NOTE: "NOTE",
  OBSERVATION: "OBSERVATION",
};

export const EXPLORATORY_PROPS = {
  REFERENCE: "REFERENCE",
  DESCRIPTION: "DESCRIPTION",
  RISK: "RISK",
  PROBABILITY: "PROBABILITY",
  IMPACT: "IMPACT",
  SUMMARY: "SUMMARY",
};

export const FORM_HEADERS = "FORM";

export const FILE_TYPES = {
  IMAGE: "image",
  VIDEO: "video",
};

export const BLANK_RICH_TEXT = {
  root: {
    children: [
      {
        children: [
          {
            detail: 0,
            format: 0,
            mode: "normal",
            style: "",
            text: "",
            type: "text",
            version: 1,
          },
        ],
        direction: null,
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1,
        textFormat: 0,
      },
    ],
    direction: null,
    format: "",
    indent: 0,
    type: "root",
    version: 1,
  },
};

export const PROCESSING_STATUS = "PROCESSING";

export const WCAG_VERSION = "2.2";

export const ACCESSIBILITY_STATUSES = {
  PASSED: "passed",
  FAILED: "failed",
  WONT_DO: "wont_do",
  NOT_APPLICABLE: "not_applicable",
  NOT_STARTED: "not_started",
};
