import React, { useEffect, useState } from "react";
import { NodeFooter } from "./nodeFooter";
import { useTranslation } from "react-i18next";
import {
  NodeCardContainer,
  CenteredContent,
  NodeType,
  TopContainer,
  Label,
  ChildContainer,
  StyledLabel,
  Banner,
  ApprovalContainer,
  FreezeIcon,
  Spacer,
} from "./NodeCard.styles";
import { useTheme } from "styled-components";
import { routes } from "../../../router/routes";
import {
  LATEST,
  NO_UPGRADE_SUBS,
  TEST_CATEGORIES,
} from "../../../static/constants/constants";
import { ReactComponent as SparkLogo } from "../../../static/assets/icon-spark.svg";
import { useHistoryWithParams } from "../../../context/hooks/historyWithParams/useHistoryWithParams";
import { NodeApprovedContainer } from "../../../components/modal/modals/runApproveModal/approval.styles";
import { approvalUtils } from "../../runView/hooks/approval/approvalUtils";
import { useNotifications } from "../../../context/hooks/useNotifications/useNotifications";
import { useSubscription } from "../../../context/subscription/subscription.provider";
import { RunPropertiesContainer, RunProperty } from "../nodeView.styles";
import { useTestTypeIcon } from "../nodeCardContainer/nodeCardTestEvolve/hooks/useTestTypeIcon";
import { useFreezeRun } from "../../../components/releases/freezeRun/useFreezeRun";
import { useUnFreezeRun } from "../../../components/releases/unFreezeRun/useUnFreezeRun";
import { useReleases } from "../../../context/releases/releases.provider";
import { Tooltip } from "../../../components/tooltip/tooltip";
import { useModal } from "../../../context/modal/modal.provider";
import { NodeCardContextMenu } from "../../../components/menus/menus";
import { ReactComponent as ExploratoryIcon } from "../../../static/assets/halo-logo-no-words.svg";

const MANUAL_PAYLOADS = [
  TEST_CATEGORIES.TEST_EVOLVE_EXPLORATORY,
  TEST_CATEGORIES.TEST_EVOLVE_ACCESSIBILITY,
];

export const NodeCard = ({
  type,
  run,
  nodeId,
  runStatus,
  status,
  children,
  label,
  getLabels,
  runProps,
  onRelease,
  frozen,
  isShared,
  noRunProperties,
  hideApproval,
  hideFreeze,
}) => {
  const [isFrozen, setIsFrozen] = useState(frozen);
  const history = useHistoryWithParams();
  const { t } = useTranslation();
  const { isDark } = useTheme();
  const { approvalIcon, getApprovedStatus, formatApproved } = approvalUtils();
  const { createNotification, types } = useNotifications();
  const { subscription } = useSubscription();
  const { returnIcon } = useTestTypeIcon();
  const { freezeRun, freezeRunSuccess, freezeRunReset } = useFreezeRun();
  const { unFreezeRun, unFreezeRunSuccess, unFreezeRunReset } =
    useUnFreezeRun();
  const { release } = useReleases();
  const { openModal, MODALS } = useModal();

  const freezeHandler = e => {
    if (isShared) return;
    e?.syntheticEvent?.stopPropagation();
    e?.syntheticEvent?.preventDefault();
    if (e?.stopPropagation) e?.stopPropagation();
    if (e?.preventDefault) e?.preventDefault();
    if (isFrozen) unFreezeRun(run, onRelease);
    else freezeRun(run, onRelease);
  };

  const nodeCardHandler = () => {
    if (run?.display_banner) {
      if (!NO_UPGRADE_SUBS.includes(subscription?.subscription_id))
        createNotification(
          types.ERROR,
          t("nodeView.upgradeMessage", {
            months: subscription?.run_retention,
          })
        );
      else
        createNotification(
          types.ERROR,
          t("nodeView.upgradeMessage2", {
            months: subscription?.run_retention,
          })
        );
    } else {
      if (isShared)
        openModal(MODALS.SHARED_RELEASE, { releaseId: release?.id });
      else {
        if (onRelease && onRelease.id)
          history.push(
            `${routes.RUN_VIEW_RELEASE.path}?page=1`
              .replace(":releaseId", onRelease.id)
              .replace(":runType", run.category)
              .replace(":label", encodeURI(encodeURIComponent(run.label)))
              .replace(":runId", frozen ? run.id : LATEST)
          );
        else
          history.push(
            `${routes.RUN_VIEW.path}?page=1`
              .replace(":nodeId", nodeId)
              .replace(":runType", run.category)
              .replace(":label", encodeURI(encodeURIComponent(run.label)))
              .replace(":runId", frozen ? run.id : LATEST)
          );
      }
    }
  };

  useEffect(() => {
    setIsFrozen(frozen);
  }, [frozen]);

  useEffect(() => {
    if (freezeRunSuccess) {
      setIsFrozen(true);
      freezeRunReset();
    }
    if (unFreezeRunSuccess) {
      setIsFrozen(false);
      unFreezeRunReset();
    }
  }, [freezeRunSuccess, unFreezeRunSuccess]);

  return (
    <NodeCardContainer
      frozen={isFrozen}
      displayBanner={run?.display_banner}
      onClick={nodeCardHandler}
    >
      <TopContainer>
        {MANUAL_PAYLOADS.includes(run.category) ? (
          <ExploratoryIcon />
        ) : (
          <SparkLogo />
        )}
        <Label>
          <NodeType>{t(`runCard.category.${type}`)}</NodeType>
        </Label>
        {isFrozen ? (
          <FreezeIcon
            title={t("releases.unFreezeRun.title")}
            onClick={freezeHandler}
            isShared={isShared}
          />
        ) : isShared ? (
          <Spacer />
        ) : (
          <NodeCardContextMenu
            run={run}
            nodeId={nodeId}
            getLabels={getLabels}
            onRelease={onRelease}
            isFrozen={isFrozen}
            freezeHandler={freezeHandler}
            hideApproval={hideApproval}
            hideFreeze={hideFreeze}
          />
        )}
      </TopContainer>
      <CenteredContent>
        <Tooltip content={label} placement="bottom">
          <StyledLabel data-tip data-for={`label-${label}`}>
            {label}
          </StyledLabel>
        </Tooltip>
        <ChildContainer>{children}</ChildContainer>
        {noRunProperties ? null : (
          <RunPropertiesContainer>
            {runProps?.map(prop => {
              const icon = returnIcon(...prop);

              return icon ? (
                <RunProperty key={`run-property-${prop[0]}`}>
                  {icon}
                </RunProperty>
              ) : null;
            })}
          </RunPropertiesContainer>
        )}

        <ApprovalContainer>
          {run.approved ? (
            <NodeApprovedContainer
              dark={isDark}
              title={formatApproved(run.approved)}
            >
              {approvalIcon(run.approved)}
              {getApprovedStatus(run.approved)}
            </NodeApprovedContainer>
          ) : null}
        </ApprovalContainer>
      </CenteredContent>

      {run?.display_banner ? (
        <Banner>
          {t(
            !NO_UPGRADE_SUBS.includes(subscription?.subscription_id)
              ? "nodeView.upgradeMessage"
              : "nodeView.upgradeMessage2",
            {
              months: subscription?.run_retention,
            }
          )}
        </Banner>
      ) : null}
      <NodeFooter
        startTime={run?.start_time}
        statusText={runStatus}
        status={status}
      />
    </NodeCardContainer>
  );
};
