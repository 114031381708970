import { Button, Loading, Modal } from "../../../index";
import { useTranslation } from "react-i18next";
import {
  ButtonsContainer,
  DetailsButton,
  DetailsContainer,
  FakeButton,
  FocusImage,
  FocusImageContainer,
  InsideModalContainer,
  ModalContainer,
  NextIcon,
  PreviousIcon,
  PreviousNextIconContainer,
} from "./entryAttachmentsModal.styles";
import { memo, useEffect, useState } from "react";
import { useGetAttachment } from "./useGetAttachment";
import { ReactComponent as TrashIcon } from "../../../../static/assets/icon-trash.svg";
import { ReactComponent as DownloadIcon } from "../../../../static/assets/icon-download.svg";
import { saveAs } from "file-saver";
import ReactPlayer from "react-player";
import {
  FILE_TYPES,
  PROCESSING_STATUS,
} from "../../../../static/constants/constants";
import { API_STATUS } from "../../../../static/constants/apiConstants";
import { useDeleteAttachment } from "../../../../views/runView/testevolveExploratory/hooks/entry/deleteAttachment";
import { useModal } from "../../../../context/modal/modal.provider";
import { compareArray } from "../../../../utils/array/compareArray";

const EntryAttachmentsModalUnMemo = ({
  closeModal,
  attachments,
  active,
  isOwnSession,
  isClosed,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("Entry Attachments")}
      size="large"
      secondary={{
        onClick: closeModal,
        text: t("btnExit"),
      }}
    >
      <ModalContents
        attachments={attachments}
        active={active}
        isClosed={isClosed}
        isOwnSession={isOwnSession}
      />
    </Modal>
  );
};

export const VideoPlayer = ({ video }) => (
  <ReactPlayer
    url={video}
    controls={true}
    className="react-player"
    width="100%"
    height="100%"
    config={{
      file: {
        attributes: {
          controlsList: "nodownload noremoteplayback",
        },
      },
    }}
  />
);

const ModalContents = ({ attachments, active, isClosed, isOwnSession }) => {
  const [attachmentsState, setAttachmentsState] = useState(attachments);
  const [focusImage, setFocusImage] = useState(active?.id);
  const [deleteMode, setDeleteMode] = useState(null);

  const { closeModal } = useModal();
  const { t } = useTranslation();

  const activeImage = attachmentsState?.find(
    a => a?.id?.toString() === focusImage?.toString()
  );

  const activeAttachmentType = activeImage?.content_type?.split("/")[0];

  const removeAttachment = id => {
    setAttachmentsState(att =>
      att.filter(e => e.id?.toString() !== id?.toString())
    );
    if (focusImage?.toString() === id?.toString()) {
      if (isPreviousAllowed()) getNextPreviousImage(1);
      else getNextPreviousImage(-1);
    }
  };

  const { attachmentObj, getAttachment } = useGetAttachment();
  const { deleteAttachment } = useDeleteAttachment(removeAttachment);

  useEffect(() => {
    getAttachment(activeImage.id);
  }, [activeImage]);

  useEffect(() => {
    if (!attachmentsState || !attachmentsState?.length) closeModal();
  }, [attachmentsState]);

  const getFocussedIndex = () =>
    attachmentsState.findIndex(e => e.id.toString() === focusImage.toString());

  const isPreviousAllowed = () => {
    const focussedIndex = getFocussedIndex();
    return focussedIndex === 0;
  };

  const isNextAllowed = () => {
    const focussedIndex = getFocussedIndex();
    return focussedIndex === attachmentsState.length - 1;
  };

  const getNextPreviousImage = add => {
    setDeleteMode(null);
    const focussedIndex = getFocussedIndex();
    setFocusImage(attachmentsState[focussedIndex + add]?.id);
  };

  return (
    <ModalContainer>
      <PreviousNextIconContainer
        data-test="previous-attachment-btn"
        disabled={isPreviousAllowed()}
        $disabled={isPreviousAllowed()}
        onClick={() => getNextPreviousImage(-1)}
      >
        <PreviousIcon $disabled={isPreviousAllowed()} />
      </PreviousNextIconContainer>
      <InsideModalContainer>
        <FocusImageContainer>
          {!attachmentObj[focusImage] ? (
            <Loading />
          ) : attachmentObj[focusImage].status === PROCESSING_STATUS ? (
            <p>{t("runView.testevolveexploratory.attachmentProcessing")}</p>
          ) : attachmentObj[focusImage].status === API_STATUS.REJECTED ? (
            <p>{t("runView.testevolveexploratory.getAttachmentsError")}</p>
          ) : attachmentObj[focusImage] &&
            attachmentObj[focusImage].attachment ? (
            activeAttachmentType === FILE_TYPES.IMAGE ? (
              <FocusImage src={attachmentObj[focusImage].attachment} />
            ) : activeAttachmentType === FILE_TYPES.VIDEO ? (
              <VideoPlayer video={attachmentObj[focusImage].attachment} />
            ) : null
          ) : null}
        </FocusImageContainer>
        {deleteMode && focusImage ? (
          <>
            <p>
              {t("runView.testevolveexploratory.areYouSureDeleteAttachment", {
                fileName: activeImage.file_name,
              })}
            </p>
            <ButtonsContainer>
              <Button secondary onClick={() => setDeleteMode(null)}>
                {t("btnCancel")}
              </Button>
              <Button danger onClick={() => deleteAttachment(deleteMode)}>
                {t("btnDelete")}
              </Button>
            </ButtonsContainer>
          </>
        ) : (
          <DetailsContainer>
            <FakeButton />
            <p>{activeImage?.file_name}</p>
            <ButtonsContainer>
              {isClosed || !isOwnSession ? null : (
                <DetailsButton>
                  <TrashIcon
                    onClick={() => setDeleteMode(activeImage.id)}
                    data-test="delete-attachment-btn"
                  />
                </DetailsButton>
              )}
              <DetailsButton>
                <DownloadIcon
                  data-test="download-attachment-btn"
                  onClick={() =>
                    saveAs(
                      attachmentObj[focusImage].attachment,
                      activeImage.file_name
                    )
                  }
                />
              </DetailsButton>
            </ButtonsContainer>
          </DetailsContainer>
        )}
      </InsideModalContainer>
      <PreviousNextIconContainer
        data-test="next-attachment-btn"
        disabled={isNextAllowed()}
        $disabled={isNextAllowed()}
        onClick={() => getNextPreviousImage(1)}
      >
        <NextIcon $disabled={isNextAllowed()} />
      </PreviousNextIconContainer>
    </ModalContainer>
  );
};

export const EntryAttachmentsModal = memo(
  EntryAttachmentsModalUnMemo,
  (prevProps, currentProps) => {
    return compareArray(
      prevProps.attachments.map(att => att.id),
      currentProps.attachments.map(att => att.id)
    );
  }
);
